<template>
  <div>
    <h1 class="mx-10 my-5 text-h5 text-center">
      {{ legendaCorrelacao }}: <b>R² = {{ (correlacao != 'NaN')? correlacao : 0 }}</b>
    </h1>
    <VueApexCharts
      v-if="this.seriesBubble[0].data.length > 0"
      ref="chart"
      class="w-100 mx-16"
      type="line"
      height="300"
      :options="chartOptionsLine"
      :series="seriesLine"
      style="margin-top: 30px; min-height: 300px"
    ></VueApexCharts>
    <VueApexCharts
      v-if="this.seriesBubble[0].data.length > 0"
      class="w-100 mx-12"
      type="bubble"
      height="350"
      :options="chartOptionsBubble"
      :series="seriesBubble"
      style="margin-top: -322px"
    ></VueApexCharts>
    <v-divider
      v-if="this.seriesBubble[0].data.length > 0"
      class="mx-5"
    ></v-divider>
    <table
      v-if="this.seriesBubble[0].data.length > 0"
      class="grey lighten-4 mx-auto my-5 pa-3"
    >
      <tr>
        <th scope="row">Discordo Totalmente</th>
        <td>{{ this.seriesBubble[0].data[3][2] }}</td>
        <td>{{ this.seriesBubble[0].data[7][2] }}</td>
        <td>{{ this.seriesBubble[0].data[11][2] }}</td>
        <td class="reta-pearson">{{ this.seriesBubble[0].data[15][2] }}</td>
      </tr>
      <tr>
        <th scope="row">Discordo Parcialmente</th>
        <td>{{ this.seriesBubble[0].data[2][2] }}</td>
        <td>{{ this.seriesBubble[0].data[6][2] }}</td>
        <td class="reta-pearson">{{ this.seriesBubble[0].data[10][2] }}</td>
        <td>{{ this.seriesBubble[0].data[14][2] }}</td>
      </tr>
      <tr>
        <th scope="row">Concordo Parcialmente</th>
        <td>{{ this.seriesBubble[0].data[1][2] }}</td>
        <td class="reta-pearson">{{ this.seriesBubble[0].data[5][2] }}</td>
        <td>{{ this.seriesBubble[0].data[9][2] }}</td>
        <td>{{ this.seriesBubble[0].data[13][2] }}</td>
      </tr>
      <tr>
        <th scope="row">Concordo Totalmente</th>
        <td class="reta-pearson">{{ this.seriesBubble[0].data[0][2] }}</td>
        <td>{{ this.seriesBubble[0].data[4][2] }}</td>
        <td>{{ this.seriesBubble[0].data[8][2] }}</td>
        <td>{{ this.seriesBubble[0].data[12][2] }}</td>
      </tr>
      <tr>
        <th
          style="
            border: none !important;
            background-color: transparent !important;
          "
          scope="row"
        ></th>
        <th scope="col">Concordo Totalmente</th>
        <th scope="col">Concordo Parcialmente</th>
        <th scope="col">Discordo Parcialmente</th>
        <th scope="col">Discordo Totalemte</th>
      </tr>
    </table>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "GraficoPearson",
  components: {
    VueApexCharts,
  },
  props: {
    perguntasFAV: {
      require: true,
      type: Array,
      default: () => [],
    },
    perguntaUM: {
      require: true,
      default: 0,
    },
    perguntaDOIS: {
      require: true,
      default: 0,
    },
  },
  data: () => ({
    correlacao: 0,
    legendaCorrelacao: "",
    //[X, Y, BubbleSize] --> bubble size is relative
    // X --> posicao da opcao da pergunta 1
    // Y --> posicao da opcao da pergunta 2
    // BubbleSize --> soma da quantidade de pessoas que marcaram a opcao de X e Y ao mesmo tempo
    seriesBubble: [
      {
        name: "",
        data: [],
      },
    ],
    chartOptionsBubble: {
      chart: {
        id: "idbubble",
        height: 350,
        type: "bubble",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointMouseEnter: function (event, chartContext, config) {
            // config.dataPointIndex :
            // [3,7,11,15]
            // [2,6,10,14]
            // [1,5,9,13]
            // [0,4,8,12]
            let opcaoX;
            let opcaoY;

            switch (config.dataPointIndex) {
              case 0:
                opcaoY = "Concordo Totalmente";
                opcaoX = "Concordo Totalmente";
                break;
              case 1:
                opcaoY = "Concordo Parcialmente";
                opcaoX = "Concordo Totalmente";
                break;
              case 2:
                opcaoY = "Discordo Parcialmente";
                opcaoX = "Concordo Totalmente";
                break;
              case 3:
                opcaoY = "Discordo Totalmente";
                opcaoX = "Concordo Totalmente";
                break;
              case 4:
                opcaoY = "Concordo Totalmente";
                opcaoX = "Concordo Parcialmente";
                break;
              case 5:
                opcaoY = "Concordo Parcialmente";
                opcaoX = "Concordo Parcialmente";
                break;
              case 6:
                opcaoY = "Discordo Parcialmente";
                opcaoX = "Concordo Parcialmente";
                break;
              case 7:
                opcaoY = "Discordo Totalmente";
                opcaoX = "Concordo Parcialmente";
                break;
              case 8:
                opcaoY = "Concordo Totalmente";
                opcaoX = "Discordo Parcialmente";
                break;
              case 9:
                opcaoY = "Concordo Parcialmente";
                opcaoX = "Discordo Parcialmente";
                break;
              case 10:
                opcaoY = "Discordo Parcialmente";
                opcaoX = "Discordo Parcialmente";
                break;
              case 11:
                opcaoY = "Discordo Totalmente";
                opcaoX = "Discordo Parcialmente";
                break;
              case 12:
                opcaoY = "Concordo Totalmente";
                opcaoX = "Discordo Totalmente";
                break;
              case 13:
                opcaoY = "Concordo Parcialmente";
                opcaoX = "Discordo Totalmente";
                break;
              case 14:
                opcaoY = "Discordo Parcialmente";
                opcaoX = "Discordo Totalmente";
                break;
              case 15:
                opcaoY = "Discordo Totalmente";
                opcaoX = "Discordo Totalmente";
                break;
              default:
                opcaoX = "";
                opcaoY = "";
                break;
            }
            config.w.config.tooltip.z.title =
              "Opção " +
              opcaoX +
              " da Pergunta 1 + Opção " +
              opcaoY +
              " da Pergunta 2: ";
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            value = "";
            return value;
          },
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          title: "",
        },
        marker: {
          show: false,
        },
      },
      fill: {
        opacity: 0.8,
      },
      xaxis: {
        max: 5,
        min: 0,
        tickAmount: 6,
        type: "category",
        overwriteCategories: [
          "",
          "Concordo Totalmente",
          "Concordo Parcialmente",
          "Discordo Parcialmente",
          "Discordo Totalmente",
          "",
        ],
        title: {
          text: "Pergunta 2",
        },
      },
      yaxis: {
        max: 5,
        min: 0,
        tickAmount: 5,
        labels: {
          show: false,
        },
        title: {
          text: "Pergunta 1",
        },
      },
    },
    seriesLine: [
      {
        name: "Reta de Correlação",
        data: [0, 1],
      },
    ],
    chartOptionsLine: {
      chart: {
        height: 330,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
          shared: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
          shared: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
    },
  }),

  watch: {
    perguntaUM() {
      this.reset();
      if (this.perguntaDOIS !== 0) this.setData();
    },
    perguntaDOIS() {
      this.reset();
      if (this.perguntaUM !== 0) this.setData();
    },
  },

  methods: {
    reset() {
      this.seriesBubble[0].data = [];
    },
    setData() {
      let dados = [];
      let x;
      let y;
      let z;
      let arrayX = [];
      let arrayY = [];
      let mediaX = [];
      let mediaY = [];
      let Sxy;
      let Sx;
      let Sy;
      this.perguntasFAV.forEach((perguntaUM) => {
        if (perguntaUM.id_pergunta == this.perguntaUM) {
          this.perguntasFAV.forEach((perguntaDOIS) => {
            if (perguntaDOIS.id_pergunta == this.perguntaDOIS) {
              perguntaUM.itens.forEach((opcaoUM) => {
                x = opcaoUM.posicao;
                z = opcaoUM.selecoes;
                if (arrayX.length < 4) arrayX.push(opcaoUM.selecoes);
                perguntaDOIS.itens.forEach((opcaoDOIS) => {
                  y = opcaoDOIS.posicao;
                  z = opcaoDOIS.selecoes + z;
                  
                  dados.push([x, y, z]);
                  z = opcaoUM.selecoes;
                  if (arrayY.length < 4) arrayY.push(opcaoDOIS.selecoes);                  
                  if (dados.length == 16)                    
                    this.seriesBubble = [
                      {
                        name: "",
                        data: dados,
                      },
                    ];
                });
              });
            }
          });
        }
      });
      mediaX = (arrayX[0] + arrayX[1] + arrayX[2] + arrayX[3]) / arrayX.length;
      mediaY = (arrayY[0] + arrayY[1] + arrayY[2] + arrayY[3]) / arrayY.length;
      //sqrt RAIZ DE
      //pow ELEVADO A
      Sx =
        Math.pow(arrayX[0] - mediaX, 2) +
        Math.pow(arrayX[1] - mediaX, 2) +
        Math.pow(arrayX[2] - mediaX, 2) +
        Math.pow(arrayX[3] - mediaX, 2);
      Sy =
        Math.pow(arrayY[0] - mediaY, 2) +
        Math.pow(arrayY[1] - mediaY, 2) +
        Math.pow(arrayY[2] - mediaY, 2) +
        Math.pow(arrayY[3] - mediaY, 2);
      Sxy =
        (arrayX[0] - mediaX) * (arrayY[0] - mediaY) +
        (arrayX[1] - mediaX) * (arrayY[1] - mediaY) +
        (arrayX[2] - mediaX) * (arrayY[2] - mediaY) +
        (arrayX[3] - mediaX) * (arrayY[3] - mediaY);
      this.correlacao = Math.pow(Sxy / Math.sqrt(Sx * Sy), 2);
      this.correlacao = this.correlacao.toFixed(2);
      console.log(this.correlacao);
      if (this.correlacao >= 0.9) {
        this.legendaCorrelacao = "Correlação positivo muito forte";
      } else if (this.correlacao <= 0.89 && this.correlacao >= 0.7) {
        this.legendaCorrelacao = "Correlação positivo forte";
      } else if (this.correlacao <= 0.69 && this.correlacao >= 0.5) {
        this.legendaCorrelacao = "Correlação positivo moderada";
      } else if (this.correlacao <= 0.49 && this.correlacao >= 0.3) {
        this.legendaCorrelacao = "Correlação positivo fraca";
      } else if (this.correlacao <= 0.29 && this.correlacao >= 0.0) {
        this.legendaCorrelacao = "Correlação positivo desprezível";
      } else if (this.correlacao <= -0.01 && this.correlacao >= -0.3) {
        this.legendaCorrelacao = "Correlação negativa desprezível";
      } else if (this.correlacao <= -0.31 && this.correlacao >= -0.5) {
        this.legendaCorrelacao = "Correlação negativa fraca";
      } else if (this.correlacao <= -0.51 && this.correlacao >= -0.7) {
        this.legendaCorrelacao = "Correlação negativa moderada";
      } else if (this.correlacao <= -0.71 && this.correlacao >= -0.9) {
        this.legendaCorrelacao = "Correlação negativa forte";
      } else if (this.correlacao <= -0.91) {
        this.legendaCorrelacao = "Correlação negativa muito forte";
      }
    },
  },
};
</script>

<style scoped>
caption {
  padding: 10px;
  caption-side: bottom;
}

table {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border: solid;
  border-color: transparent;
  border-radius: 6px;
  letter-spacing: 1px;
  font-family: sans-serif;
  font-size: 0.8rem;
}

td,
th {
  background-color: #ffffff;
  border: solid;
  border-radius: 6px;
  border-color: transparent;
  padding: 5px 10px;
}

td {
  background-color: #ffffff;
  border: solid;
  border-color: transparent;
  border-radius: 6px;
  text-align: center;
}
.reta-pearson {
  background-color: rgba(0, 143, 251, 0.85);
  color: white !important;
}
</style>
