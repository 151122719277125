<template>
  <div>
    <v-row class="pb-0">
      <Grid :tamanho="12">
        <v-card flat>
          <v-card-title> </v-card-title>
          <v-card-subtitle> </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  background-color="white"
                  class="ma-3 text-subtitle-1 w-5-em"
                  label="Pergunta 1"
                  :items="setPerguntasFAV()"
                  item-text="descricao"
                  item-value="id_pergunta"
                  outlined
                  dense
                  hide-details
                  v-model="perguntaUM"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  background-color="white"
                  class="ma-3 text-subtitle-1 w-5-em"
                  label="Pergunta 2"
                  :items="setPerguntasFAV()"
                  item-text="descricao"
                  item-value="id_pergunta"
                  outlined
                  dense
                  hide-details
                  v-model="perguntaDOIS"
                ></v-select>
              </v-col>
            </v-row>
            <GraficoPearson
              :perguntasFAV="setPerguntasFAV()"
              :perguntaUM="perguntaUM"
              :perguntaDOIS="perguntaDOIS"
            />
            <v-row>
              <v-col cols="12">
                <h3>Perguntas removidas da analise</h3>
              </v-col>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Grupo</th>
                        <th class="text-left">Pergunta</th>
                        <th class="text-left">Grupo seleções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in setPerguntasForaPadraoFAV()"
                        :key="item.id_pergunta"
                      >
                        <td>{{ item.sigla }}</td>
                        <td>{{ item.descricao }}</td>
                        <td>
                          <template>
                            <b>Concordo totalmente</b>:
                            {{ item.selecoes[0] ? item.selecoes[0] : 0 }}<br />
                            <b>Concordo parcialmente</b>:
                            {{ item.selecoes[1] ? item.selecoes[1] : 0 }}<br />
                            <b>Discordo parcialmente</b>:
                            {{ item.selecoes[2] ? item.selecoes[2] : 0 }}<br />
                            <b>Discordo totalmente</b>:
                            {{ item.selecoes[3] ? item.selecoes[3] : 0 }}<br />
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </Grid>
    </v-row>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import store from "@/store";
import { ref, computed, onUpdated, onBeforeMount } from "@vue/composition-api";
import themeConfig from "@themeConfig";
import GraficoPearson from "./../GraficoPearson.vue";

export default {
  components: {
    Grid,
    GraficoPearson,
  },

  setup() {
    const $themeColors = themeConfig.themes.light;
    const $chartColors = themeConfig.chartColors;

    const gruposData = computed(
      () => store.getters["pesquisas/getGruposPainel"]
    );

    const perguntaUM = ref(0);
    const perguntaDOIS = ref(0);
    const perguntas = ref([]);
    var perguntasRemovidas = ref([]);

    onBeforeMount(() => {
      perguntasRemovidas = setPerguntasForaPadraoFAV();
      console.log(perguntasRemovidas);
    });

    function setPerguntasFAV() {
      let perguntasFAV = [];

      Object.keys(gruposData.value).forEach((grupo) => {
        Object.keys(gruposData.value[grupo].perguntas).forEach((pergunta) => {
          if (
            gruposData.value[grupo].perguntas[pergunta].selecoes &&
            gruposData.value[grupo].perguntas[pergunta].selecoes.length > 0
          ) {
            if (
              gruposData.value[grupo].perguntas[pergunta].selecoes.filter(
                (el) => el === 0
              ).length === 0
            ) {
              perguntasFAV.push(gruposData.value[grupo].perguntas[pergunta]);
            }
            // gruposData.value[grupo].perguntas[pergunta].selecoes.filter(el => el===0).length === 0;
            // gruposData.value[grupo].perguntas[pergunta].selecoes.forEach(el => {
            //   if(el > 0)
            //   {
            //     console.log(el);
            //
            //   }
            // });
          }
        });
      });
      perguntasFAV.sort((a, b) => a.posicao - b.posicao);
      perguntas.value = perguntasFAV;
      return perguntasFAV;
    }
    function setPerguntasForaPadraoFAV() {
      let perguntasForaParao = [];

      Object.keys(gruposData.value).forEach((grupo) => {
        Object.keys(gruposData.value[grupo].perguntas).forEach((pergunta) => {
          if (
            gruposData.value[grupo].perguntas[pergunta].selecoes &&
            gruposData.value[grupo].perguntas[pergunta].selecoes.length > 0
          ) {
            if (
              gruposData.value[grupo].perguntas[pergunta].selecoes.filter(
                (el) => el === 0
              ).length > 0
            ) {
              perguntasForaParao.push(
                gruposData.value[grupo].perguntas[pergunta]
              );
            }
          }
        });
      });
      return perguntasForaParao;
    }

    return {
      setPerguntasFAV,
      setPerguntasForaPadraoFAV,

      perguntaUM,
      perguntaDOIS,
      perguntasRemovidas,
    };
  },
};
</script>

<style>
</style>